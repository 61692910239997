import React from "react";
import Auth from "../../utils/Auth";
import { RouteComponentProps } from "react-router-dom";

// UI
import uiStyles from "../../assets/styles/styles";
import {
  CssBaseline,
  Typography,
  Container,
  WithStyles,
  Dialog,
  CircularProgress,
  DialogContent,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { Query } from "react-apollo";
import { SHOW_RESTAURANT_QUERY_GUEST } from "./queries.gql";
import { Check, GetApp } from "@material-ui/icons";

import logo from "../../assets/img/logo.svg";
import appleBadge from "../../assets/img/appstore-badge.svg";
import googleBadge from "../../assets/img/google-play-badge.png";
import Loading from "../../components/Loading";

interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {
  match: any;
}

type State = {
  summonDialog: boolean;
  storesDialog: boolean;
  successText: boolean;
};

class Restaurant extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      summonDialog: false,
      storesDialog: false,
      successText: false,
    };

    Auth.clear();
  }

  render() {
    const { classes } = this.props;
    const { summonDialog, storesDialog, successText } = this.state;

    const { id } = this.props.match.params;

    return (
      <>
        <Container
          component="main"
          maxWidth="sm"
          className={classes.background}
        >
          <Query query={SHOW_RESTAURANT_QUERY_GUEST} variables={{ id }}>
            {({ loading, error, data, refetch }: any) => {
              if (loading) return <Loading />;
              if (error) return null;

              const { restaurant } = data;
              return (
                <>
                  <div className={classes.main}>
                    <CssBaseline />
                    <div className={classes.paper_auth}>
                      <img src={logo} className={classes.logo} alt="Kellner" />
                      <Typography variant="h5" className={classes.title}>
                        {restaurant && restaurant.name}
                      </Typography>

                      <button
                        className={classes.buttonOutlined}
                        onClick={() => this.setState({ storesDialog: true })}
                      >
                        Zamawiam i płacę online
                      </button>
                    </div>
                  </div>

                  <Dialog
                    onClose={() => this.setState({ summonDialog: false })}
                    open={summonDialog}
                    disableBackdropClick={successText ? false : true}
                    disableEscapeKeyDown={successText ? false : true}
                  >
                    <DialogContent>
                      {successText ? (
                        <>
                          <Typography
                            variant="h5"
                            className={classes.dialogContent}
                          >
                            <Check fontSize="large" />
                            <br />
                            Kellner zaraz podejdzie!
                          </Typography>
                        </>
                      ) : (
                        <CircularProgress
                          size="60px"
                          className={classes.dialogContent}
                        />
                      )}
                    </DialogContent>
                  </Dialog>

                  <Dialog
                    onClose={() => this.setState({ storesDialog: false })}
                    open={storesDialog}
                  >
                    <DialogContent className={classes.flexColumn}>
                      <Typography
                        variant="h5"
                        className={classes.dialogContent}
                      >
                        <GetApp fontSize="large" />
                        <br />
                        Pobierz naszą aplikację
                      </Typography>
                      <a
                        href="https://play.google.com/store/apps/details?id=pl.kellner.client"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img
                          src={googleBadge}
                          alt="Google Play"
                          width="230px"
                        />
                      </a>
                      <a
                        href="https://apps.apple.com/us/app/kellner/id1538563707"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img
                          src={appleBadge}
                          alt="Apple Appstore"
                          width="200px"
                        />
                      </a>
                    </DialogContent>
                  </Dialog>

                  <div className={classes.menu}>
                    <CssBaseline />
                    <div className={classes.menuInside}>
                      <img
                        src={restaurant && restaurant.mainImageUrl}
                        className={classes.logo}
                        alt={restaurant && restaurant.name}
                        style={{
                          maxWidth: "100%",
                          marginBottom: "20px",
                        }}
                      />
                      {restaurant &&
                        restaurant.menuCategories &&
                        restaurant.menuCategories
                          .filter(
                            (item) =>
                              item.public &&
                              item.menuItems.filter(
                                (menuItem) =>
                                  menuItem.public &&
                                  (!menuItem.inStock || menuItem.inStock > 0)
                              ).length > 0
                          )
                          .map((item, index) => {
                            return (
                              <div key={index}>
                                <Typography
                                  variant="h6"
                                  className={classes.catTitle}
                                >
                                  {item.name}
                                </Typography>
                                <hr />
                                <div className={classes.catItems}>
                                  {item.menuItems &&
                                    item.menuItems
                                      .filter(
                                        (item) =>
                                          item.public &&
                                          (!item.inStock || item.inStock > 0)
                                      )
                                      .map((subitem, subindex) => {
                                        return (
                                          <div
                                            className={classes.flexBetween}
                                            key={`${index} ${subindex}`}
                                          >
                                            {subitem.name}
                                            <span style={{ flexShrink: 0 }}>
                                              {
                                                subitem.price
                                                  .toString()
                                                  .split(".")[0]
                                              }
                                              ,
                                              {
                                                subitem.price
                                                  .toFixed(2)
                                                  .toString()
                                                  .split(".")[1]
                                              }{" "}
                                              zł
                                            </span>
                                          </div>
                                        );
                                      })}
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </>
              );
            }}
          </Query>
        </Container>
      </>
    );
  }
}

export default withStyles(uiStyles)(Restaurant);
