export type DictionaryType = {
  id: string
  name: string
  sysName: string
  values: {
    id: string
    name: string
  }
}

export type DictionariesState = DictionaryType[]

export const UPDATE_DICTIONARIES = 'UPDATE_DICTIONARIES'

interface UpdateDictionariesAction {
  type: typeof UPDATE_DICTIONARIES
  dictionaries: DictionaryType[]
}


export type DictionariesActionTypes = UpdateDictionariesAction