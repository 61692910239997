import { createStyles, Theme } from '@material-ui/core/styles'

const uiStyles = (theme: Theme) => createStyles({
    close: {
        padding: theme.spacing(0.5),
    },
    snackbarContent: {
        flexWrap: 'nowrap',
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        flexWrap: 'nowrap',
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
})

export default uiStyles