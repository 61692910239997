import { createStyles, Theme } from "@material-ui/core/styles";

import bg from "../img/bg.png";

const uiStyles = (theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        fontFamily: "'Noto Sans', sans-serif",
        minHeight: "100vh",
        padding: "0px",
      },
    },
    background: {
      backgroundColor: "#1f4c49",
      backgroundImage: `url(${bg})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top center",
      backgroundSize: "100% auto",
      padding: "0px",
    },
    main: {
      position: "relative",
      paddingTop: "40px",
      paddingBottom: "10px",
      backgroundColor: "rgba(55, 107, 103, 0.65)",
    },
    menu: {
      position: "relative",
      padding: theme.spacing(1),
      backgroundColor: "#1f4c49",
    },
    menuInside: {
      padding: theme.spacing(1),
      backgroundColor: "white",
      borderRadius: "10px",
    },
    title: {
      color: "#f9cea4",
      fontWeight: 700,
      fontSize: "20px",
      textAlign: "left",
      marginTop: "40px",
    },
    subtitle: {
      color: "#f9cea4",
      fontSize: "17px",
      fontWeight: 400,
      textAlign: "left",
    },
    catTitle: {
      color: "black",
      fontWeight: 700,
      fontSize: "14px",
      textTransform: "uppercase",
      textAlign: "left",
    },
    catItems: {
      marginBottom: theme.spacing(2),
    },
    dialogContent: {
      color: "#124445",
      fontWeight: 700,
      fontSize: "20px",
      textAlign: "center",
      marginTop: "10px",
      marginBottom: "20px",
    },
    button: {
      fontFamily: "'Noto Sans', sans-serif",
      backgroundColor: "#f9cea4",
      color: "#1f4c49",
      width: "100%",
      borderRadius: "25px",
      outline: "0px",
      border: "0px",
      padding: "13px",
      fontSize: "19px",
      fontWeight: 400,
      cursor: "pointer",
      marginTop: "20px",
    },
    buttonOutlined: {
      fontFamily: "'Noto Sans', sans-serif",
      backgroundColor: "#124445",
      color: "#f9cea4",
      width: "100%",
      borderRadius: "25px",
      outline: "0px",
      border: "1px solid #f9cea4",
      padding: "13px",
      fontSize: "19px",
      fontWeight: 400,
      cursor: "pointer",
      marginTop: "20px",
    },
    flexBetween: {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "13px",
      fontWeight: 700, 
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: "20px",
    },
    logo: {
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
      maxHeight: "150px",
    },
    paper: {
      position: "relative",
    },
    paper_auth: {
      padding: theme.spacing(2),
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    alignRight: {
      textAlign: "right",
    },
    table: {
      width: "100%",
    },
    box: {
      position: "relative",
      marginTop: theme.spacing(4),
    },
    btn_add_value: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
    btn_back: {
      minWidth: theme.spacing(4),
      maxWidth: theme.spacing(4),
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    fab_edit: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    bottomSpace: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    leftSpace: {
      marginLeft: theme.spacing(4),
    },
    fieldDate: {
      marginTop: theme.spacing(1),
      minWidth: "30%",
    },
    lockIcon: {
      marginRight: theme.spacing(1.5),
      color: theme.palette.grey[500],
    },
    divider: {
      margin: theme.spacing(4, 0, 0, 0),
    },
    chip: {
      marginLeft: theme.spacing(2),
    },
    formControl: {
      minWidth: "100%",
    },
    cell: {
      padding: theme.spacing(0.5),
    },
    cellHead: {
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
      textTransform: "uppercase",
      fontSize: "0.75em",
      lineHeight: 1.4,
    },
    textField: {
      width: "100%",
    },
    inputLabel: {
      margin: theme.spacing(2, 0, 1, 0),
    },
    typography: {
      margin: theme.spacing(0, 0, 0, 0),
    },
    tabs: {
      marginTop: theme.spacing(2),
    },
    menuTabs: {
      marginTop: theme.spacing(3),
    },
    tab: {
      display: "none",
    },
    tabShow: {
      display: "block",
    },
    img: {
      margin: "0 auto",
      display: "block",
      width: "auto",
      height: "100%",
      maxHeight: 70,
      maxWidth: "100%",
    },
    imgDel: {
      margin: "2px auto",
      zIndex: 100,
      width: 30,
      height: 30,
    },
    alignCenter: {
      textAlign: "center",
    },
    chipWithMargin: {
      margin: theme.spacing(1, 1, 1, 0),
    },
  });

export default uiStyles;
