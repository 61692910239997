import {
    UserState,
    UPDATE_USER,
    UserActionTypes,
    InterfaceState,
    InterfaceActionTypes,
    UPDATE_FLASH_MESSAGE,
    RESET_FLASH_MESSAGE,
    UPDATE_NAVI,
    UPDATE_CURRENT_VIEW,
    DELETE_USER
} from './types'


const initialUserState: UserState = {
    id: '',
    email: '',
    createdAt: '',
    profileId: '',
    firstName: '',
    lastName: '',
    postcode: '',
    street: '',
    city: '',
    birthDate: '',
}

export function userReducer(
    state = initialUserState,
    action: UserActionTypes
): UserState {
    switch (action.type) {
        case UPDATE_USER: {
            return {
                ...state,
                ...action.user
            }
        }
        case DELETE_USER: {
            return {
                ...state,
                id: '',
                email: '',
                createdAt: '',
                profileId: '',
                firstName: '',
                lastName: '',
                postcode: '',
                street: '',
                city: '',
                birthDate: '',
            }
        }
        default:
            return state
    }
}

const initialInterfaceState: InterfaceState = {
    currentView: {
        name: ''
    },
    navi: {
        name: ''
    },
    flashMessage: {
        open: false,
        type: 'default',
        message: '',
        autoHideDuration: null,
        refreshAction: false,
        closeAction: true,
    },
}

export function interfaceReducer(
    state = initialInterfaceState,
    action: InterfaceActionTypes
): InterfaceState {
    switch (action.type) {
        case UPDATE_FLASH_MESSAGE: {
            return {
                ...state,
                flashMessage: {
                    ...state.flashMessage,
                    ...action.payload
                }
            }
        }
        case RESET_FLASH_MESSAGE: {
            return {
                ...state,
                flashMessage: {
                    ...state.flashMessage,
                    open: false,
                    autoHideDuration: null,
                    refreshAction: false,
                    closeAction: true,
                }
            }
        }
        case UPDATE_NAVI: {
            return {
                ...state,
                navi: {
                    ...action.view
                }
            }
        }
        case UPDATE_CURRENT_VIEW: {
            return {
                ...state,
                currentView: {
                    ...action.view
                }
            }
        }
        default:
            return state
    }
}