import Table from "../containers/Table";
import Restaurant from "../containers/Restaurant";

const ROUTES = {
  table: {
    path: "/restaurants/:id/tables/:tableId",
    component: Table,
  },
  restaurant: {
    path: "/restaurants/:id",
    component: Restaurant,
  },
};

export default ROUTES;
