import React from "react";
import { Route, Switch } from "react-router";
import { connect } from "react-redux";
import { updateUser } from "../../store/interface/actions";

import ROUTES from "../../routes";
import FlashMessage from "../../components/FlashMessage";

type AProps = {
  updateUser: typeof updateUser;
};

class App extends React.Component<AProps> {
  render() {
    return (
      <>
        <Switch>
          <Route path={ROUTES.table.path} component={ROUTES.table.component} />
          <Route
            path={ROUTES.restaurant.path}
            component={ROUTES.restaurant.component}
          />
        </Switch>

        <FlashMessage />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, { updateUser })(App);
