import gql from "graphql-tag";

export const SHOW_RESTAURANT_QUERY_GUEST = gql`
  query restaurant($id: ID!) {
    restaurant(id: $id) {
      id
      name
      mainImageUrl
      tableDeliveryPossible
      tables {
        id
        name
      }
      menuCategories {
        id
        name
        public
        menuItems {
          allergens {
            name
            id
          }
          imageSignedBlobId
          imageUrl
          calories
          price
          description
          id
          inStock
          name
          public
          containsAlcohol
        }
      }
    }
  }
`;

export const SUMMON_WAITER = gql`
  mutation serviceRequestCreate($tableId: ID!, $serviceType: Service) {
    serviceRequestCreate(
      input: { tableId: $tableId, serviceType: $serviceType }
    ) {
      errors {
        field
        messages
      }
    }
  }
`;
